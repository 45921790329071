
import axios from 'axios';
export default {
    
        async createAccounts(params)  {
            return await axios.post(`accounts/create` , params)
        },
        async createAccountsList(params)  {
            return await axios.post(`accounts/create/list` , params)
        },
        async updateAccountsColumn(column , value , data)  {
            return await axios.put(`accounts/update_list?${column}=${value}` , data)
        },
        async deleteAccountsList(list)  {
            return await axios.delete(`accounts/delete_list` , {
                data: {
                    list:list
                }
            })
        },
        async reportAccounts(column , value)  {
            return await axios.get(`accounts/report?${column}=${value}`)
        },
        async getAllAccounts()  {
            return await axios.get(`accounts/all`)
        },
        async getOneAccounts(account_id)  {
            return await axios.get(`accounts/all/${account_id}`)
        },
        async getAccountsByColumn(column , value)  {
            return await axios.get(`accounts/filter?column=${column}&value=${value}`)
        },
        async deleteAccounts(account_id)  {
            return await axios.delete(`accounts/delete/${account_id}`)
        },
        async updateAccounts(account_id , params)  {
            return await axios.put(`accounts/update/${account_id}` , params)
        },
}
        

<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
                <v-layout row wrap px-4 mb-4>
                    <v-flex xs9>
                        <v-breadcrumbs class="px-0" :items="$store.getters.language.data.accounts.list_path">
                            <template v-slot:item="{item}">
                                <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                    {{ item.title }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-flex>
                    <v-flex xs3></v-flex>
                </v-layout>
                <form @submit.prevent="addAccounts" autocomplete="off">
                    <v-layout row wrap>
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-text-field v-model="accounts.account_name" type="text" :label="$store.getters.language.data.accounts.account_name" dense
                                class="mx-1" filled outlined required>
                            </v-text-field>
                        </v-flex>
                    
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-text-field v-model="accounts.account_phone" type="text" :label="$store.getters.language.data.accounts.account_phone" dense
                                class="mx-1" filled outlined>
                            </v-text-field>
                        </v-flex>
                
                        <v-flex xs12 lg3 xl3 md3 sm4>
                            <v-select class="mx-1" clearable :items="[{text: $store.getters.language.data.accounts.store, value: 'store'}, {text: $store.getters.language.data.accounts.out_store, value: 'out_store'}]" v-model="accounts.account_type" dense  filled outlined item-text="text"
                                item-value="value" :return-object="false" :label="$store.getters.language.data.accounts.account_type" required>
                            </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-select class="mx-1" clearable :items="[{text: $store.getters.language.data.yes, value: 'true'}, {text: $store.getters.language.data.no, value: 'false'}]" v-model="accounts.account_is_default" dense  filled outlined item-text="text"
                                item-value="value" :return-object="false" :label="$store.getters.language.data.accounts.account_is_default">
                            </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit" :disabled="buttonDisable">{{$store.getters.language.data.accounts.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card>
            <v-card-text>
                <v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0"
                    item-key="account_id">
                    <template v-slot:[`item.account_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/account_transfers-list/'+item.account_id"  color="primary" class="mx-1">
                                <v-icon> mdi-bank-transfer-out </v-icon>
                            </v-btn>
                            <v-btn icon :to="'/accounts-list/'+item.account_id"  color="teal" class="mx-1">
                                <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-btn color="danger" icon class="mx-1" @click="selectAccounts(item)" >
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <template v-slot:[`item.account_is_default`]="{ item }">
                        <div>
                            <v-chip color="primary" class="white--text" small v-if="item.account_is_default == 'true'"> {{ $store.getters.language.data.yes }} </v-chip>
                            <v-chip color="danger" class="white--text" small v-else> {{ $store.getters.language.data.no }} </v-chip>
                        </div>
                    </template>
                    <template v-slot:[`item.account_status`]="{ item }">
                        <div>
                            <v-chip color="primary" class="white--text" small v-if="item.account_status == 'enable'"> {{ $store.getters.language.data.enabled }} </v-chip>
                            <v-chip color="danger" class="white--text" small v-else> {{ $store.getters.language.data.disabled }} </v-chip>
                        </div>
                    </template>
                    <template v-slot:[`item.account_type`]="{ item }">
                        <div>
                            {{ $store.getters.language.data.accounts[item.account_type] }}
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            
            <v-card-actions v-if="selected_rows.length > 0">
                <v-btn color="error" @click="deleteAccountsList">{{$store.getters.language.data.accounts.delete_list_btn}}</v-btn>
            </v-card-actions>
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>
<v-dialog v-model="delete_dialog" persistent max-width="400">
    <v-card>
        <v-card-title>
            {{$store.getters.language.data.accounts.delete_question}}
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="elevation-0" @click="delete_dialog = false">
                {{$store.getters.language.data.accounts.cancel_btn}}
            </v-btn>
            <v-btn color="error" class="elevation-0" @click="deleteAccounts(selected_accounts.account_id)">
                {{$store.getters.language.data.accounts.yes_btn}}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/accounts.request.js'
    export default {
        data() {
            return {
                accounts: {},
                search: '',
                loading : false,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                selected_accounts : {},
                delete_dialog: false,
                headers: [
                    { 
                        text: this.$store.getters.language.data.accounts.account_name,
                        align: 'start',
                        sortable: true,
                        value: 'account_name',
                    },
                    { 
                        text: this.$store.getters.language.data.accounts.account_phone,
                        align: 'start',
                        sortable: true,
                        value: 'account_phone',
                    },
                    { 
                        text: this.$store.getters.language.data.accounts.account_type,
                        align: 'start',
                        sortable: true,
                        value: 'account_type',
                    },
                    { 
                        text: this.$store.getters.language.data.accounts.account_status,
                        align: 'start',
                        sortable: true,
                        value: 'account_status',
                    },
                    { 
                        text: this.$store.getters.language.data.accounts.account_is_default,
                        align: 'start',
                        sortable: true,
                        value: 'account_is_default',
                    },   
                    {
                        text: this.$store.getters.language.data.actions,
                        align: 'start',
                        sortable: true,
                        value: 'account_id',
                    }
                ],
            }
        },
        computed: {
            rows() {
                return this.$store.getters.accounts_list
            },
            stores(){
                return this.$store.getters.stores_list
            },
            buttonDisable() {
                return !this.accounts.account_name ||
                    !this.accounts.account_type ||
                    !this.accounts.account_is_default
            }
        },
        mounted(){
            
        },
        methods: {
            addAccounts() {
                if(this.accounts.account_is_default == 'true' && this.rows.filter(obj => obj.account_is_default == 'true').length > 0) {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.one_default_account,
                        color: 'info'
                    }
                } else {
                    this.loading_btn = true
                    this.accounts.store_id = this.$store.getters.store.store_id;
                    this.accounts.account_status = 'enable';
                    requests.createAccounts(this.accounts).then(r => {
                        if (r.status == 200) {
                            this.accounts = {}
                            this.$store.state.accounts_list.push(
                                r.data.new_data
                            )
                            this.snackbar = {
                                value: true,
                                text: this.$store.getters.language.data.messages.add_success,
                                color: 'success'
                            }
                        } else {
                            this.snackbar = {
                                value: true,
                                text: this.$store.getters.language.data.messages.failed,
                                color: 'error'
                            }
                        }
                    })
                    .finally(() => {
                        this.loading_btn = false
                    })
                }

            },
            deleteAccounts(account_id) {
                requests.deleteAccounts(account_id).then(r => {
                    this.delete_dialog = false
                    if (r.status == 200) {
                        this.$store.state.accounts_list = this.$store.state.accounts_list.filter(f =>{
                            return f.account_id != account_id
                        })
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.delete_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
            },
            deleteAccountsList() {
                let ids = this.selected_rows.map(m => m.account_id)
                requests.deleteAccountsList(ids).then(r => {
                    if (r.status == 200) {
                        this.$store.state.accounts_list = this.$store.state.accounts_list.filter(f =>{  
                            return ids.indexOf(f.account_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.delete_success,
                            color: 'success'
                        }
                        this.selected_rows = [];
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
            },
            selectAccounts(i) {
                this.selected_accounts = i
                this.delete_dialog = true
            }
        },
    }
</script>
                    